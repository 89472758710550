import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';
import paginationComponent from '@/components/Pagination'

export default {
    name: 'Users',
    data() {
        return {
            users: [],
            totalUsers: 0,
            scopes: []
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout,
        paginationComponent
    },
    mounted() {
        this.getUsers()
    },
    methods: {
        pageChange: function (nr) {
            this.getUsers(nr);
        },
        getUsers(pageParamter = 1, limitParamter = this.paginationPerPage()) {
            const self = this;
            const withCounts = '?withCount=childrens';
            const appends = '&append=name';
            const includes = '&include=roles:name';
            const limit = '&limit=' + limitParamter;
            const page = '&page=' + pageParamter;
            const scopes = '&scope=' + this.scopes.join(',')
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/users' + withCounts + appends + includes + limit + page + scopes,
                method: 'GET'
            }).then(response => {
                self.users = response.data.rows;
                self.totalUsers = response.data.total_data;
            })
        },
        updateScopes(name) {
            this.scopes = [name]

            this.getUsers();
        }
    }
}
